import { render, staticRenderFns } from "./CarouselKeyboard.vue?vue&type=template&id=0cde0f4f&"
import script from "./CarouselKeyboard.vue?vue&type=script&lang=js&"
export * from "./CarouselKeyboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports